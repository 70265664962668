header {
  position: relative;
  z-index: 99;
  .navbar-brand {
    img {
      height: 37px;
      margin-top: 3px;
    }
  }
}
main {
  padding-top: 66px;
}

.mobile-sidenav {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.pagination-box {
  // .pagination {
  //   flex-wrap: wrap;
  // }
  .page-link {
    color: var(--dark);
  }
  .page-item {
    &.active {
      .page-link {
        background: var(--dark);
        border-color: var(--dark);
      }
    }
  }
}
.pagination {
  margin-top: 30px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  a {
    border: none;
    font-size: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 4px;
    overflow: hidden;
    padding: 7px 13px;
    display: block;
    color: #212529 !important;

    span {
      border: none;
    }
    &:hover {
      &:not(.active) {
        background-color: var(--primary);
        color: white !important;
      }
    }
    &:focus {
      outline: none;
    }
  }
  :global(.active) {
    a {
      font-weight: 700;
      background: var(--primary);
      color: white !important;
    }
  }
  li {
    padding: 6px;
    &:global(.break-me) {
      a {
        background: transparent;
        box-shadow: none;
        margin-top: -3px;
        color: white !important;
      }
    }
    &:global(.disabled) {
      opacity: 0.4;
      pointer-events: none;
    }

    &.active {
      a {
        background: var(--primary);
        border-color: var(--primary);
        color: white !important;
      }
    }
  }
}

.table {
  td {
    vertical-align: middle;
  }

  .rec-image-box {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .image-box {
    width: 100px;
  }

  .action {
    width: 80px;
    text-align: center;
  }
  td:last-child {
    text-align: center;
  }
}
